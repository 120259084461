export default function addToCartEvent(product) {
  const price = product?.price_range?.maximum_price;
  return gtag('event', 'add_to_cart', {
    currency: 'EUR',
    value: price?.final_price?.value,
    items: [
      {
        currency: 'EUR',
        quantity: 1,
        item_name: product?.name,
        item_category: product?.categories ? product?.categories[0]?.name : '',
        item_category2: product?.categories ? product?.categories[1]?.name : '',
        item_category3: product?.categories ? product?.categories[2]?.name : '',
        item_brand: product?.mpbrand?.value,
        price: price?.final_price?.value,
        discount: price?.discount?.value
      }
    ]
  });
}
