export const toastStyles = ({ isDesktop, padding, background }) => {
  return {
    background,
    padding: padding || '0.5rem',
    whiteSpace: isDesktop ? 'nowrap' : 'initial',
    maxWidth: isDesktop ? 'max-content' : 'initial',
    position: 'relative',
    top: isDesktop ? '-30px' : 0
  };
};
