import styled, { th, css } from '@xstyled/styled-components';
import { FlexCenter, CrossOut } from '@/UI/utils';

// Regular product card

export const ProductCardBox = styled.divBox`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 270px;
  padding: 0 1.25rem;
  margin-right: 0.5rem;
  border: 0.7px solid #d8d9e5;
  border-radius: 12px;
  background-color: white;
  font-weight: 500;

  @media (min-width: desktop) {
    padding: '1rem 1rem 2rem';

    ${({ zoomOff }) =>
      zoomOff &&
      css`
        &:hover {
          border: 0.7px solid ${th.color('teal-100')};
        }
      `}
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;

    span#discount_badge {
      position: absolute;
      left: 8;
      top: 8;
      padding: 4px 8px;
      color: white;
      background: #334a5e;
      font-size: 13px;
      font-weight: 400;
      border-radius: 12px;
      z-index: 2;
    }

    img {
      transform: scale(${({ scale }) => scale || 0.8}) translateY(10%);
      transition: transform 0.4s;
    }

    &:hover {
      img {
        transform: ${({ zoomOff, hoverScale }) =>
          !zoomOff && `scale(${hoverScale || 0.9}) translateY(10%)`};
      }
    }
  }
`;

export const ProductTitle = styled.p`
  height: 40px;
  font-size: xxsmall;
  text-align: center;
  line-clamp: 2;
  z-index: 2;
  overflow: hidden;

  @media (min-width: desktop) {
    height: 44px;
    max-width: ${({ scale }) => (scale ? '180px' : 'initial')};

    font-size: ${({ scale }) => !scale && th.fontSize('xsmall')};

    margin-top: ${({ scale }) => (scale ? 0 : '1rem')};

    margin-bottom: ${({ scale }) => (scale ? 0 : '0.75rem')};
  }
`;

export const ProductPrice = styled.pBox`
  ${FlexCenter};
  padding: 0.625rem 0 0.5rem;
  color: teal-300;
  font-size: xxsmall;
  text-align: center;

  @media (min-width: desktop) {
    font-size: ${({ scale }) =>
      scale ? th.fontSize('xsmall') : th.fontSize('small')};
  }

  span {
    position: relative;
    margin-right: 0.5rem;
    font-size: xxsmall;
    color: blue-200;

    @media (min-width: desktop) {
      font-size: xsmall;
    }

    &:after {
      ${CrossOut}
    }
  }
`;

export const ButtonsContainer = styled.divBox`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 1.5rem;
  bottom: 1rem;
  white-space: nowrap;
  opacity: 0;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up {
    0% {
      bottom: 0.5rem;
    }
    100% {
      bottom: 1rem;
    }
  }

  ${ProductCardBox}:hover & {
    animation: fade-in 0.4s forwards ease-out, slide-up 0.4s forwards;
  }

  @media (max-width: desktop) {
    display: none;
  }
`;

// Cross-sell product card

export const CrossSellCardBox = styled(ProductCardBox)`
  border-radius: 22px;

  @media (min-width: desktop) {
    padding: 0 1rem;
  }
`;

export const CrossSellProductTitle = styled(ProductTitle)`
  @media (min-width: desktop) {
    margin-top: -1rem;
  }
`;

export const CrossSellProductPrice = styled(ProductPrice)`
  @media (min-width: desktop) {
    font-size: xsmall;
    margin-bottom: 0.5rem;
  }
`;

export const DiscountPercentage = styled.span`
  ${FlexCenter};
  position: absolute;
  bottom: -1.4rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 38px;
  height: 38px;
  color: white;
  background-color: pink-100;
  border-radius: 50%;
  font-size: xxsmall;
  font-weight: 700;
  z-index: 1;
`;
