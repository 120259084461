import styled from '@xstyled/styled-components';

const RatingWrapper = styled.divBox`
  display: flex;
  z-index: 1;

  span {
    svg {
      transform: ${({ scale }) => `scale(${scale})` || 'scale(1.25)'};
    }

    &:nth-child(2) {
      font-size: xxsmall;
      font-weight: 400;
      color: #9c9c9c;
      transform: translateY(5%);
    }
  }
`;

export default function StarRating({ rating, scale, ...rest }) {
  return (
    <RatingWrapper {...rest} scale={scale}>
      <span role="img" aria-label="Star rating">
        {Star}
      </span>
      <span>{rating / 20}/5</span>
    </RatingWrapper>
  );
}

const Star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <path
      id="Polygon_25"
      data-name="Polygon 25"
      d="M7.107,1.775a1,1,0,0,1,1.786,0l1.353,2.687A1,1,0,0,0,11,5l2.987.434a1,1,0,0,1,.551,1.709L12.393,9.21a1,1,0,0,0-.291.889l.5,2.924a1,1,0,0,1-1.444,1.058l-2.7-1.393a1,1,0,0,0-.917,0l-2.7,1.393A1,1,0,0,1,3.4,13.022L3.9,10.1a1,1,0,0,0-.291-.889L1.467,7.145a1,1,0,0,1,.551-1.709L5,5a1,1,0,0,0,.749-.54Z"
      fill="#96e7e7"
    />
  </svg>
);
