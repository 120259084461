import { useEffect, useState } from 'react';
import getProductPrice from 'utils/getProductPrice';

export default function useProductCardPrice(prices) {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const { priceValue, discount } = getProductPrice(prices);

    setPrice({ value: priceValue, discount });
  }, [prices]);

  return price;
}
