import toast from 'react-hot-toast';
import { Box } from '@xstyled/styled-components';
import { toastStyles } from './toastStyles';
import { FlexBox } from '../Primitives/FlexBox';

export default function newItemInCartToast({ name, onClick, isDesktop ,text1, text2}) {

  return toast(
    () => (
      <FlexBox
        as="span"
        role="button"
        aria-label={`Go to product ${name} page`}
        align="center"
        spaceX="1rem"
        cursor="pointer"
        onClick={onClick}
      >
        <Box
          as="span"
          role="img"
          aria-label="Product added to cart"
          position="relative"
          top={4}
        >
          {Cart}
        </Box>
        <span>
          {text1}<b> {name} </b> {text2}
        </span>
      </FlexBox>
    ),
    { style: toastStyles({ isDesktop, background: '#C8FDEB' }) }
  );
}

const Cart = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g id="Group_1297" data-name="Group 1297" transform="translate(-684 -371)">
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="13"
        cy="13"
        r="13"
        transform="translate(684 371)"
        fill="#e6fff6"
      />
      <g
        id="Group_180"
        data-name="Group 180"
        transform="translate(137.397 -439.778)"
      >
        <path
          id="Path_114"
          data-name="Path 114"
          d="M574.037,831.445h-1.3v-1.3a.648.648,0,1,0-1.3,0v1.3h-1.3a.648.648,0,0,0,0,1.3h1.3v1.3a.648.648,0,0,0,1.3,0v-1.3h1.3a.648.648,0,0,0,0-1.3Z"
          transform="translate(-6.332 -4.925)"
          fill="#1b1b1b"
        />
        <path
          id="Path_115"
          data-name="Path 115"
          d="M563.524,829.761h-7.876a.649.649,0,0,1-.594-.381l-2.053-7.4h13.205l-.176.638a.648.648,0,0,0,1.25.345l.4-1.458a.649.649,0,0,0-.625-.821h-.889l-1.275-2.654a1.93,1.93,0,0,0-1.81-1.236h-.6a1.623,1.623,0,0,0-1.587-1.3h-2.593a1.623,1.623,0,0,0-1.587,1.3h-.6a1.908,1.908,0,0,0-1.79,1.191l-1.3,2.7h-.889a.648.648,0,0,0-.624.822l2.3,8.269a1.956,1.956,0,0,0,1.827,1.281h7.876a.648.648,0,0,0,0-1.3ZM558.306,816.8H560.9a.324.324,0,0,1,0,.648h-2.593a.324.324,0,0,1,0-.648ZM555.52,818.5a.641.641,0,0,1,.6-.41h.9a1.612,1.612,0,0,0,1.288.648H560.9a1.612,1.612,0,0,0,1.288-.648h.9a.666.666,0,0,1,.622.455l1.026,2.138H554.474Z"
          fill="#1b1b1b"
        />
      </g>
    </g>
  </svg>
);
