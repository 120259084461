import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useViewportWidth } from '@xstyled/styled-components';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import {
  cartSidebarAtom,
  addToCartErrorAtom,
  productVariantsAtom
} from 'global/store';
import {
  ADD_SIMPLE_PRODUCT_TO_CART,
  ADD_CONF_PRODUCT_TO_CART
} from 'graphql/mutations';
import addToCartEvent from 'lib/Google/events/addToCart.gtag';
import useCartId from 'hooks/cart/useCartId';
import CartToast from '@/UI/Toast/CartToast';
import { useTranslation } from 'next-i18next';

export default function useAddToCart(product) {
  const { t } = useTranslation();

  const router = useRouter();
  const viewportWidth = useViewportWidth();
  const isDesktop = viewportWidth >= 1024;

  const cartId = useCartId();

  const [addSimpleProductToCart, { loading: simpleProductLoading }] =
    useMutation(ADD_SIMPLE_PRODUCT_TO_CART);
  const [addConfProductToCart, { loading: confProductLoading }] = useMutation(
    ADD_CONF_PRODUCT_TO_CART
  );

  const [variants, setVariants] = useAtom(productVariantsAtom);
  const [error, setError] = useAtom(addToCartErrorAtom);
  const setCartOpen = useUpdateAtom(cartSidebarAtom);

  const simpleProduct = product?.__typename === 'SimpleProduct';
  const sku = product?.sku;

  useEffect(() => {
    if (error) {
      let timer = setTimeout(() => setError(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const showSuccessToast = () => {
    CartToast({
      name: product?.name,
      onClick: () => setCartOpen(true),
      isDesktop,

      text1: t('ui.product'),
      text2: t('ui.added_to_cart')
    });
  };
  const showErrorToast = () => {
    toast.error(t('toast.toast_error'), {
      style: {
        position: 'relative',
        top: isDesktop ? '-30px' : 0
      }
    });
  };

  const addProductToCart = async () => {
    addToCartEvent(product); //GTAG add to cart

    if (typeof window !== 'undefined') {
      fbq('track', 'AddToCart', {
        content_type: 'product',
        content_ids: [product?.id],
        content_name: product?.name,
        content_category: product?.categories
          ? product?.categories[product?.categories.length - 1].name
          : '',
        value: product?.price_range?.maximum_price?.final_price?.value,
        contents: [{ id: product?.id, quantity: 1 }],
        currency: 'EUR'
      });
    }

    if (simpleProduct) {
      await addSimpleProductToCart({ variables: { cartId, sku } });
      showSuccessToast();
    } else {
      if (product?.configurable_options?.length === variants?.length) {
        await addConfProductToCart({
          variables: {
            cartId,
            sku,
            selectedOptions: variants?.map(variant => variant.uid)
          }
        });
        showSuccessToast();
        setVariants([]);
      } else {
        setError(true);
        showErrorToast();
      }
    }
  };

  const addToCart = () => {
    if (router.route === '/toode/[toode]' || simpleProduct) {
      addProductToCart();
    } else {
      router.push(`/toode/${product?.url_key}`);
    }
  };

  return { addToCart, loading: simpleProductLoading || confProductLoading };
}
