export const AddToCart = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.853"
    height="15.557"
    viewBox="0 0 16.853 15.557"
  >
    <g
      id="Group_180"
      data-name="Group 180"
      transform="translate(-551.5 -815.5)"
    >
      <path
        id="Path_114"
        data-name="Path 114"
        d="M574.037,831.445h-1.3v-1.3a.648.648,0,1,0-1.3,0v1.3h-1.3a.648.648,0,0,0,0,1.3h1.3v1.3a.648.648,0,0,0,1.3,0v-1.3h1.3a.648.648,0,0,0,0-1.3Z"
        transform="translate(-6.332 -4.925)"
        fill="#1b1b1b"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M563.524,829.761h-7.876a.649.649,0,0,1-.594-.381l-2.053-7.4h13.205l-.176.638a.648.648,0,0,0,1.25.345l.4-1.458a.649.649,0,0,0-.625-.821h-.889l-1.275-2.654a1.93,1.93,0,0,0-1.81-1.236h-.6a1.623,1.623,0,0,0-1.587-1.3h-2.593a1.623,1.623,0,0,0-1.587,1.3h-.6a1.908,1.908,0,0,0-1.79,1.191l-1.3,2.7h-.889a.648.648,0,0,0-.624.822l2.3,8.269a1.956,1.956,0,0,0,1.827,1.281h7.876a.648.648,0,0,0,0-1.3ZM558.306,816.8H560.9a.324.324,0,0,1,0,.648h-2.593a.324.324,0,0,1,0-.648ZM555.52,818.5a.641.641,0,0,1,.6-.41h.9a1.612,1.612,0,0,0,1.288.648H560.9a1.612,1.612,0,0,0,1.288-.648h.9a.666.666,0,0,1,.622.455l1.026,2.138H554.474Z"
        fill="#1b1b1b"
      />
    </g>
  </svg>
);

export const Compare = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.444"
    height="15.743"
    viewBox="0 0 12.444 15.743"
  >
    <g
      id="Group_1298"
      data-name="Group 1298"
      transform="translate(-843.988 -1494.101)"
    >
      <path
        id="Path_127"
        data-name="Path 127"
        d="M8677.959,1501.245l-3.406,3.405,3.406,3.406"
        transform="translate(-7829.814 -6.083)"
        fill="none"
        stroke="#9f9f9f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_128"
        data-name="Path 128"
        d="M3.406,0,0,3.405,3.406,6.811"
        transform="translate(855.682 1508.784) rotate(180)"
        fill="none"
        stroke="#9f9f9f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_129"
        data-name="Path 129"
        d="M8666.568,1506.068h8.328"
        transform="translate(-7821.83 -7.5)"
        fill="none"
        stroke="#9f9f9f"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_130"
        data-name="Path 130"
        d="M0,0H8.328"
        transform="translate(855.682 1505.378) rotate(180)"
        fill="none"
        stroke="#9f9f9f"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const FilterBoxArrows = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.698"
    height="11.567"
    viewBox="0 0 21.698 11.567"
  >
    <g
      id="Group_1301"
      data-name="Group 1301"
      transform="translate(-15.293 -466.293)"
    >
      <g
        id="Group_222"
        data-name="Group 222"
        transform="translate(36.284 467) rotate(90)"
      >
        <g id="Group_220" data-name="Group 220">
          <line
            id="Line_108"
            data-name="Line 108"
            x1="3.973"
            y1="3.973"
            transform="translate(0 3.973)"
            fill="none"
            stroke="#52c1d3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_109"
            data-name="Line 109"
            x1="3.973"
            y2="3.973"
            fill="none"
            stroke="#52c1d3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="Group_1300" data-name="Group 1300">
        <g
          id="Group_221"
          data-name="Group 221"
          transform="translate(16 477.153) rotate(-90)"
        >
          <g id="Group_220-2" data-name="Group 220" transform="translate(0)">
            <line
              id="Line_108-2"
              data-name="Line 108"
              x1="3.973"
              y1="3.973"
              transform="translate(0 3.973)"
              fill="none"
              stroke="#52c1d3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_109-2"
              data-name="Line 109"
              x1="3.973"
              y2="3.973"
              fill="none"
              stroke="#52c1d3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <line
            id="Line_110"
            data-name="Line 110"
            x1="10.153"
            transform="translate(0 3.973)"
            fill="none"
            stroke="#52c1d3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <line
          id="Line_110-2"
          data-name="Line 110"
          x1="10.153"
          transform="translate(32.311 467) rotate(90)"
          fill="none"
          stroke="#52c1d3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export const Filter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.671"
    height="9"
    viewBox="0 0 11.671 9"
  >
    <g
      id="Group_1303"
      data-name="Group 1303"
      transform="translate(-303.247 -467.881)"
    >
      <path
        id="Path_401"
        data-name="Path 401"
        d="M619.418,1366.381H608.747"
        transform="translate(-305 -898)"
        fill="none"
        stroke="#52c1d3"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <path
        id="Path_402"
        data-name="Path 402"
        d="M615.062,1366.381h-6.315"
        transform="translate(-305 -894)"
        fill="none"
        stroke="#52c1d3"
        strokeLinecap="round"
        strokeWidth="1"
      />
      <path
        id="Path_403"
        data-name="Path 403"
        d="M611.9,1366.381h-3.157"
        transform="translate(-305 -890)"
        fill="none"
        stroke="#52c1d3"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const ZeroProducts = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g transform="translate(-803 -436)">
        <circle
          cx="13"
          cy="13"
          r="13"
          transform="translate(803 436)"
          fill="#fefefe"
        />
        <path
          d="M2.32-5.52a.908.908,0,0,0,.92.88.908.908,0,0,0,.92-.88l.86-7.52a.829.829,0,0,0-.8-1.02H2.26a.829.829,0,0,0-.8,1.02ZM1.5-1.62v.04A1.683,1.683,0,0,0,3.24.12a1.683,1.683,0,0,0,1.74-1.7v-.04a1.683,1.683,0,0,0-1.74-1.7A1.683,1.683,0,0,0,1.5-1.62Z"
          transform="translate(813 456)"
          fill="#1b1b1b"
        />
      </g>
    </svg>
  );
};
