import { Box } from '@xstyled/styled-components';
import useAddToCart from 'hooks/cart/useAddToCart';
import Button from '.';
import { AddToCart as AddToCartIcon } from '@/UI/Icons/Catalogue';
import { useTranslation } from 'next-i18next';

/**
 * AddToCartButton
 *
 * Button for product card that executes
 * a function to add product to cart
 *
 * @prop {object} product - the product data object
 */

export default function AddToCartButton({ product, ...rest }) {
  const { addToCart } = useAddToCart(product);
  const { t } = useTranslation();

  return (
    <Button
      display="flex"
      spaceX="0.5rem"
      whiteSpace="nowrap"
      {...rest}
      onClick={addToCart}
    >
      <span role="img" aria-label="Shopping cart">
        {AddToCartIcon}
      </span>
      <Box as="span" fontSize="14px" fontWeight="400" color="black">
      {t('product_main.basket_add')}
      </Box>
    </Button>
  );
}
